import { OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AppConfigService } from '../../../core/services/app-config.service';
var UpdatePasswordSucessPageComponent = /** @class */ (function () {
    function UpdatePasswordSucessPageComponent(ngxSpinner, router, appConfig) {
        this.ngxSpinner = ngxSpinner;
        this.router = router;
        this.appConfig = appConfig;
        this.templateString = {
            BANNER_TXT: "Technology That Moves You",
            UPDATE_STATUS: 'Congratulations!',
            UPDATE_INFO: 'Your password has been changed sucessfully, please choose an application',
        };
    }
    // Detect window size
    UpdatePasswordSucessPageComponent.prototype.onresize = function () {
        this.getWindowSize();
    };
    UpdatePasswordSucessPageComponent.prototype.ngOnInit = function () {
        this.mobilifyHRProductName = this.appConfig.getConfig('mobilifyHR_ProductName').toString();
        this.movePro360ProductName = this.appConfig.getConfig('movePro360_ProductName').toString();
        this.benefitsBuilderProductName = this.appConfig.getConfig('benefitsBuilder_ProductName').toString();
        this.getWindowSize();
        this.ngxSpinner.hide();
    };
    UpdatePasswordSucessPageComponent.prototype.redirectToSelectedProduct = function (destPage) {
        this.ngxSpinner.show();
        var urls = this.appConfig.getConfig(destPage).toString().split('|');
        this.ngxSpinner.hide();
        this.router.navigate(['/externalRedirect', { externalUrl: urls[0] }], {
            skipLocationChange: true,
        });
    };
    UpdatePasswordSucessPageComponent.prototype.getWindowSize = function () {
        var windowWidth = window.innerWidth;
        if (windowWidth <= 959) {
            this.responsiveView = true;
        }
        else {
            this.responsiveView = false;
        }
    };
    return UpdatePasswordSucessPageComponent;
}());
export { UpdatePasswordSucessPageComponent };
