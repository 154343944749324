import { OnInit, OnDestroy } from '@angular/core';
import { copyright } from 'src/app/core/constants';
import { UserPreferencesService } from 'src/app/core/services/user-preferences.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(userPreferencesService, userPreference, appConfig, router, cookieService, cookieLogoSvc) {
        var _this = this;
        this.userPreferencesService = userPreferencesService;
        this.userPreference = userPreference;
        this.appConfig = appConfig;
        this.router = router;
        this.cookieService = cookieService;
        this.cookieLogoSvc = cookieLogoSvc;
        /* MobilifyHR Logo */
        this.mobilifyHRLogo = false;
        /* Move Pro 360 Logo */
        this.movePro360Logo = false;
        /* Benefits Builder Logo */
        this.benefitsBuilderLogo = false;
        /* Supplier Portal Logo */
        this.supplierPortalLogo = false;
        /*For the source app URL */
        this.referredURL = '';
        /*Available Sites*/
        this.authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices'];
        /*For showing logout button */
        this.showLogoutButton = false;
        /**property to print copyright string */
        this.copyright = copyright;
        // checkTokenCookie determines whether the logout button should be displayed
        // it is a variable holding the anonymous function for the event listener
        // this allows storage actions to be assigned to the event during onInit
        // and removed onDestroy
        this.checkTokenCookie = function (event) {
            if (_this.cookieService.get('car-ses-tok')) {
                _this.showLogoutButton = true;
            }
            else {
                _this.showLogoutButton = false;
                _this.clearCookies();
                sessionStorage.clear();
            }
        };
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Listener for removal of token cookie
        window.addEventListener('storage', this.checkTokenCookie, true);
        this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(function (val) {
            if (val) {
                _this.referredURL = val.replace(/(\/#|\/|#)$/, '');
                var res = _this.cookieLogoSvc.setLogo(_this.referredURL);
                _this.logoConfig = { logo: res, type: 'alt' };
            }
            else {
                _this.logoConfig = { logo: _this.logoConfig.logo, type: _this.logoConfig.type };
            }
        });
        // Show logout button if cookie is set via logging in
        if (this.cookieService.get('car-ses-tok')) {
            this.showLogoutButton = true;
        }
    };
    /* performs a redirect to logout */
    HeaderComponent.prototype.logout = function () {
        this.router.navigateByUrl('logout');
    };
    /* clears the cookies */
    HeaderComponent.prototype.clearCookies = function () {
        this.cookieLogoSvc.removeCookies(['car-ses-tok']);
    };
    /** To unsubscribe to the event listener */
    HeaderComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('storage', this.checkTokenCookie, true);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
