import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { interval } from "rxjs";
import { PipeTransform } from "@angular/core";
import { MatSnackBar } from '@angular/material';
var SpringboardTimeoutComponent = /** @class */ (function () {
    function SpringboardTimeoutComponent(ref, _snackBar) {
        this.ref = ref;
        this._snackBar = _snackBar;
        this.counter = 120;
        this.tick = 1000;
    }
    SpringboardTimeoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.countDown = interval(this.tick).subscribe(function () {
            --_this.counter;
            if (_this.counter === 0)
                _this._snackBar.dismiss();
            _this.ref.detectChanges();
        });
    };
    SpringboardTimeoutComponent.prototype.ngOnDestroy = function () {
        this.countDown.unsubscribe();
        this.countDown = null;
    };
    return SpringboardTimeoutComponent;
}());
export { SpringboardTimeoutComponent };
var FormatTimePipe = /** @class */ (function () {
    function FormatTimePipe() {
    }
    FormatTimePipe.prototype.transform = function (value) {
        var minutes = Math.floor(value / 60);
        return (("00" + minutes).slice(-2) +
            ":" +
            ("00" + Math.floor(value - minutes * 60)).slice(-2));
    };
    return FormatTimePipe;
}());
export { FormatTimePipe };
