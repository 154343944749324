import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(router, route, authService, appConfig, cookieLogoSvc) {
        this.router = router;
        this.route = route;
        this.authService = authService;
        this.appConfig = appConfig;
        this.cookieLogoSvc = cookieLogoSvc;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            // Remove cookies
            _this.cookieLogoSvc.removeAllCookies();
            // Sign out of Okta and redirect
            _this.authService.signOut().then(function () {
                _this.navigateToLogin(params); // Redirect to login once all logout processing is done
            }).catch(function () {
                _this.navigateToLogin(params); // Not Okta authenticated
            });
        });
    };
    LogoutComponent.prototype.navigateToLogin = function (params) {
        var extras;
        if (params && params.code) {
            extras = {
                queryParams: {
                    code: params.code
                }
            };
        }
        this.router.navigate(['login'], extras);
    };
    return LogoutComponent;
}());
export { LogoutComponent };
