import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppConfigService } from './core/services/app-config.service';
import { UserPreferencesService } from './core/services/user-preferences.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatSnackBar } from '@angular/material';
import { SessionTimeoutComponent } from './public/components/session-timeout/session-timeout.component';
/** Base application component */
/** @ignore */
var AppComponent = /** @class */ (function () {
    /** Component instantiation */
    function AppComponent(router, appConfig, userPreferencesService, route, idle, snackBar) {
        var _this = this;
        this.router = router;
        this.appConfig = appConfig;
        this.userPreferencesService = userPreferencesService;
        this.route = route;
        this.idle = idle;
        this.snackBar = snackBar;
        this.isTimeoutWarning = false;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd && event.url.includes('changePassword')) {
                _this.idle.setIdleName('idle_warning');
                _this.idle.setIdle(840); // setIdle for 14mins (14*60)
                _this.idle.setTimeout(60);
                _this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                _this.idle.onTimeoutWarning.subscribe(function (timeout) {
                    if (!_this.isTimeoutWarning) {
                        _this.snackBar.openFromComponent(SessionTimeoutComponent, {
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom',
                            data: 'You will be logged out in a minute due to inactivity'
                        });
                        _this.isTimeoutWarning = true;
                    }
                });
                _this.idle.onTimeout.subscribe(function () {
                    _this.snackBar.dismiss();
                    _this.isTimeoutWarning = false;
                    _this.router.navigate(['login']);
                });
                _this.idle.onIdleEnd.subscribe(function () {
                    _this.isTimeoutWarning = false;
                });
                _this.idle.watch();
            }
        });
    }
    /** Base init method */
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.appId) { // Get application id
                var domain = void 0;
                switch (params.appId) {
                    case '1': {
                        domain = String(_this.appConfig.getConfig('movePro360'));
                        break;
                    }
                    case '2': {
                        domain = String(_this.appConfig.getConfig('mobilifyHR'));
                        break;
                    }
                    case '3': {
                        domain = String(_this.appConfig.getConfig('mobilifyUI'));
                        break;
                    }
                    case '4': {
                        domain = String(_this.appConfig.getConfig('supplierPortal'));
                        break;
                    }
                    case '5': {
                        domain = String(_this.appConfig.getConfig('benefitsBuilder'));
                        break;
                    }
                    case '6': {
                        domain = String(_this.appConfig.getConfig('compensationServices'));
                        break;
                    }
                }
                _this.userPreferencesService.setPreference({
                    Key: 'referrer',
                    Value: domain,
                    Persist: false
                });
                _this.userPreferencesService.setPreference({
                    Key: 'appId',
                    Value: params.appId,
                    Persist: false
                });
            }
            if (params.appName) {
                _this.userPreferencesService.setPreference({
                    Key: 'appName',
                    Value: params.appName,
                    Persist: false
                });
            }
            if (params.urlPath) {
                _this.userPreferencesService.setPreference({
                    Key: 'urlPath',
                    Value: params.urlPath,
                    Persist: false
                });
            }
        });
        this.navSub = this.router.events.subscribe(function (event) {
            if ((event instanceof NavigationEnd)) { // Google Analytics
                // (window as any).ga('set', 'page', `${window.location.pathname}#${event.urlAfterRedirects}`);
                // (window as any).ga('send', 'pageview');
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'pageview',
                        page: {
                            path: window.location.pathname + '#' + event.urlAfterRedirects
                        }
                    });
                }
            }
        });
        // if ((window as any).ga) {
        //   const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('google_analytics');
        //   (window as any).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
        // }
    };
    /** Component Angular destructor lifecycle hook */
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.navSub) {
            this.navSub.unsubscribe();
        }
    };
    return AppComponent;
}());
export { AppComponent };
