import { OnInit, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelfRegistrationValidationService } from 'src/app/core/services/self-registration-validation.service';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/core/services/app-config.service';
/** Class for  SelfRegistrationValidateUser */
var SelfRegistrationValidationComponent = /** @class */ (function () {
    /**
     * Base constructor
     * @param fb Formbuilder variable
     * @param persistenceService PersistenceService
     * @param spinner to get NgxSpinner
     * @param validationService SelfRegistrationValidationService
     * @param snackBar to get MatSnackBar
     */
    function SelfRegistrationValidationComponent(fb, validationService, persistenceService, snackBar, appConfig, ngxSpinner) {
        this.fb = fb;
        this.validationService = validationService;
        this.persistenceService = persistenceService;
        this.snackBar = snackBar;
        this.appConfig = appConfig;
        this.ngxSpinner = ngxSpinner;
        /** Output parameter step number notified to parent component by emiting */
        this.notify = new EventEmitter();
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /** variable to store user validation details */
        this.userValidation = {};
        /** variable to check no of invalid OTP attemps */
        this.invalidOTPCount = 0;
        /** variable to enable Continue button */
        this.isContinueBtnEnable = false;
        /** Used to hold toggle the phone/email otp display */
        this.showEmail = false;
        /** Used to hold value whether otp toggle is present */
        this.otpToggle = false;
        /** variable to to check OTP sent via Email or not */
        this.otpEmailSent = false;
        /** variable to to check OTP sent via Phone or not */
        this.otpPhoneSent = false;
        /** string constants to be displayed in self registration validation page */
        this.templateString = {
            PHONE_PLACEHOLDER_TEXT: 'Phone number provided:',
            emailPlaceholderText: 'Business Email Address provided:',
            SEND_CODE_BTN: 'Send Code',
            RESEND_CODE: 'Resend the Code',
            CONTINUE_BTN: 'Continue'
        };
        /** error constants to be displayed in self registration page */
        this.errorString = {
            INVALID_OTP: 'Invalid security code, please try again.',
            OTP_ATTEMPTS_EXCEEDED: 'Invalid security code. You have exceeded five attempts, please request for a new code.'
        };
        this.contentTextPrefix = 'If you cannot receive text messages on this phone, please contact your representative at';
        this.contentTextPostfix = ' and provide them a suitable phone number that can. One message per registration attempt ' +
            'will be sent with a one time password to the number provided. Message and data rates may apply.';
        this.contentEmailPrefix = 'If you do not recognize this email address, please contact your representative at';
        this.clientContactName = 'Cartus';
        this.phoneNumberHiddenChar = '(***) *** - ';
        this.otpTextPrefix = 'You should receive a security code momentarily. If you do not, we can ';
        this.optTextPostfix = ' at your request. Once you receive a code, it will be valid for only 5 minutes before expiring.';
    }
    /** To Initialize Component */
    SelfRegistrationValidationComponent.prototype.ngOnInit = function () {
        this.PhoneNumberLastChars = this.userDetails.phoneNumber.substr(this.userDetails.phoneNumber.length - 4);
        if (this.userDetails.roleName === 'candidate' || this.userDetails.roleName === 'transferee') {
            this.isCandidateorTransferee = true;
        }
        else {
            this.isCandidateorTransferee = false;
        }
        // otpToggle relies on the otpEmailAddess field to have a value returned
        if (this.userDetails.otpEmailAddress) {
            this.otpToggle = true;
            var email = this.userDetails.otpEmailAddress;
            var emailDomain = email.substr(email.indexOf("@"));
            var unmaskedUsername = email.slice(0, 2);
            var usernameLength = email.length - emailDomain.length;
            var maskedChar = '*';
            if (usernameLength > 1) {
                var unmaskedUsernameLength = email.length - emailDomain.length;
                var maskedPattern = "" + maskedChar.repeat(unmaskedUsernameLength - 2);
                this.maskedEmail = "" + unmaskedUsername + maskedPattern + emailDomain;
            }
            else {
                this.maskedEmail = "" + maskedChar + emailDomain;
            }
        }
        this.createControl();
    };
    /** To Create form Controls */
    SelfRegistrationValidationComponent.prototype.createControl = function () {
        this.regValidationForm = this.fb.group({
            otp: ['', Validators.required]
        });
    };
    /** Store the current step in session */
    // tslint:disable-next-line: use-life-cycle-interface
    SelfRegistrationValidationComponent.prototype.ngAfterViewInit = function () {
        this.persistenceService.set('currentStep', JSON.stringify(this.step), { type: StorageType.SESSION });
    };
    /**
     * method to toggle process to send OTP to the user
     * clears the form values, error and validator
     */
    SelfRegistrationValidationComponent.prototype.toggleOTPMethod = function () {
        this.showEmail = !this.showEmail;
        this.regValidationForm.get('otp').clearValidators();
        this.regValidationForm.get('otp').setValue('');
        this.regValidationForm.get('otp').setErrors(null);
    };
    /**
     * method to send OTP to the user
     * this.showEmail boolean specifies OTP via email or phone
     * @return A response containing the result (string)
     */
    SelfRegistrationValidationComponent.prototype.sendOTP = function () {
        var _this = this;
        var user = {};
        user.userId = this.userDetails.userId;
        this.showEmail ? user.emailAddress = this.userDetails.otpEmailAddress : user.phoneNumber = this.userDetails.phoneNumber;
        user.product = this.userDetails.product;
        user.systemOfOrigin = this.userDetails.systemOfOrigin;
        var openSnackbar = false;
        if (!this.appConfig.getConfig('byPassOtp')) {
            this.subscription.add(this.validationService
                .sendOTP(user, true)
                .subscribe(function (response) {
                if (response === true) {
                    console.log(user);
                    user.emailAddress ? _this.otpEmailSent = true : _this.otpPhoneSent = true;
                }
                else {
                    openSnackbar = true;
                }
            }));
        }
        else {
            user.emailAddress ? this.otpEmailSent = true : this.otpPhoneSent = true;
        }
        if (openSnackbar) {
            this.snackBar.open('We are unable to process your request at this time. Please try again later.', undefined, { duration: 5000 });
        }
    };
    /** Method to resend OTP to the User */
    SelfRegistrationValidationComponent.prototype.resendOTP = function () {
        this.isContinueBtnEnable = false;
        this.regValidationForm.get('otp').setValue('');
        this.regValidationForm.get('otp').setErrors(null);
        this.regValidationForm.setErrors({ 'invalid': true });
        this.invalidOTPCount = 0;
        this.sendOTP();
        this.serveSnackBar();
    };
    /** Method to validate the enter OTP */
    SelfRegistrationValidationComponent.prototype.validateOTP = function () {
        var _this = this;
        this.ngxSpinner.show();
        this.userValidation.otp = this.regValidationForm.get('otp').value;
        this.userValidation.userId = this.userDetails.userId;
        if (this.appConfig.getConfig('byPassOtp')) {
            this.ngxSpinner.hide();
            var currentStep = void 0;
            currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
            this.notify.emit(currentStep);
        }
        else {
            this.ngxSpinner.hide();
            this.subscription.add(this.validationService
                .validateOTP(this.userValidation, true)
                .subscribe(function (response) {
                if (response === false) {
                    _this.ngxSpinner.hide();
                    _this.invalidOTPCount = _this.invalidOTPCount + 1;
                    _this.isContinueBtnEnable = _this.invalidOTPCount >= 5 ? false : true;
                    if (_this.invalidOTPCount < 5) {
                        _this.regValidationForm.controls['otp'].setErrors({
                            invalidOTP: true
                        });
                    }
                    else {
                        _this.regValidationForm.controls['otp'].setErrors({
                            exceededAttempts: true
                        });
                    }
                }
                else {
                    _this.ngxSpinner.hide();
                    var currentStep = void 0;
                    currentStep = parseInt(_this.persistenceService.get('currentStep', StorageType.SESSION), 10);
                    _this.notify.emit(currentStep);
                }
            }));
        }
    };
    /**
     * Method to check invalid otp count
     * Checks for form control validator and if missing sets back
     */
    SelfRegistrationValidationComponent.prototype.checkOTP = function () {
        if (!this.regValidationForm.controls['otp'].hasOwnProperty('required')) {
            this.regValidationForm.controls['otp'].setValidators(Validators.required);
        }
        if (this.invalidOTPCount >= 5) {
            this.regValidationForm.setErrors({ 'invalid': false });
        }
    };
    /**
     * To set error message to fields
     * @param fieldName - Field Name
     */
    SelfRegistrationValidationComponent.prototype.getErrorMessage = function (fieldName) {
        if (fieldName === 'OTP') {
            if (this.regValidationForm.get('otp').hasError('required')) {
                this.isContinueBtnEnable = false;
            }
            return this.regValidationForm.get('otp').hasError('required')
                ? 'Please enter a Security Code'
                : '';
        }
    };
    /** To Display Snack Bar when OTP is resent. */
    SelfRegistrationValidationComponent.prototype.serveSnackBar = function () {
        var config = {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            data: 'Code Re-Sent to ',
            duration: 5000
        };
        // need to determine what method was sent before -- add a new global boolean variable like otpViaPhone
        config.data = this.showEmail ? config.data + " Email" : config.data + " Mobile #";
        this.snackBar.openFromComponent(CustomSnackbarComponent, config);
    };
    return SelfRegistrationValidationComponent;
}());
export { SelfRegistrationValidationComponent };
