import { OnInit } from '@angular/core';
/** Avatar component to display the user Initials */
var AvatarComponent = /** @class */ (function () {
    /** Base constructor method */
    function AvatarComponent() {
    }
    /** Component Angular initialization lifecycle hook */
    AvatarComponent.prototype.ngOnInit = function () {
    };
    return AvatarComponent;
}());
export { AvatarComponent };
