import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { copyright } from 'src/app/core/constants';
var PrivacyStatementComponent = /** @class */ (function () {
    function PrivacyStatementComponent(titleService, spinner) {
        this.titleService = titleService;
        this.spinner = spinner;
        /**property to print copyright string */
        this.copyright = copyright;
    }
    PrivacyStatementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.titleService.setTitle('Privacy Notice');
        this.logoConfig = { logo: 'cartus', type: 'main' };
        this.spinner.show();
        setTimeout(function () { _this.spinner.hide(); }, 2000);
    };
    return PrivacyStatementComponent;
}());
export { PrivacyStatementComponent };
