import { OnInit, EventEmitter } from '@angular/core';
import { AppConfigService } from '../../../core/services/app-config.service';
import { Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';
/**user context component to display user context details */
var UserContextComponent = /** @class */ (function () {
    /** user context constructor method */
    function UserContextComponent(appConfig, router, liveAnnouncer) {
        this.appConfig = appConfig;
        this.router = router;
        this.liveAnnouncer = liveAnnouncer;
        this.contextChange = new EventEmitter();
        this.loggedOut = new EventEmitter();
    }
    /** Base init method */
    UserContextComponent.prototype.ngOnInit = function () { };
    // /** SSO logout - invalidate token */
    // logout(): void {
    //   sessionStorage.clear();
    //   //Todo - invalidate okta token
    //   //Todo - display logged message?
    // }
    UserContextComponent.prototype.changeContext = function () {
        this.contextChange.emit(true);
    };
    UserContextComponent.prototype.logout = function () {
        this.loggedOut.emit(true);
    };
    UserContextComponent.prototype.announceContext = function () {
        this.liveAnnouncer.announce(this.selectedContext.firstName + ' ' +
            this.selectedContext.clientLegalName + ' ' +
            this.selectedContext.clientNo);
    };
    return UserContextComponent;
}());
export { UserContextComponent };
