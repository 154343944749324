import { OnInit } from '@angular/core';
import { copyright } from 'src/app/core/constants';
import { Router } from '@angular/router';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(router) {
        this.router = router;
        this.class = 'app-footer';
        /**property to print copyright string */
        this.flexProperty = 'column';
        this.showFullWidth = false;
        this.cookiesAccepted = false;
        this.copyright = copyright;
    }
    FooterComponent.prototype.ngOnInit = function () {
        //check cookies policy accepted
        var cookies = document.cookie.split(';');
        var cookiesItems = cookies.map(function (val) { return { key: val.split('=')[0].trim(), value: val.split('=')[1] }; });
        var hasKey = cookiesItems.find(function (item) { return item.key === "notice_preferences"; });
        if (typeof hasKey !== "undefined") {
            this.cookiesAccepted = true;
        }
        else {
            this.cookiesAccepted = false;
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
