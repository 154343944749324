import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersistenceService, StorageType } from 'angular-persistence';
import { ManageUserSharedService } from '../../../../core/services/manage-user-shared.service';
/** Class for  SelfRegistrationSuccess */
var SelfRegistrationSuccessComponent = /** @class */ (function () {
    /**
     * Base constructor
     * @param rourer for route navigations
     * @param persistenceService PersistenceService
     */
    function SelfRegistrationSuccessComponent(router, persistenceService, manageMoveSharedService) {
        this.router = router;
        this.persistenceService = persistenceService;
        this.manageMoveSharedService = manageMoveSharedService;
        /** string constants to be displayed in self registration success page */
        this.templateString = {
            LOGIN_BTN: 'Log In',
            CONGRATS_TXT: 'Congratulations!',
            ACCOUNT_CREATED_TXT: 'Your account has been created successfully'
        };
    }
    SelfRegistrationSuccessComponent.prototype.ngOnInit = function () {
        this.manageMoveSharedService.updateData(this.userDetails);
    };
    /** Store the current step in session */
    // tslint:disable-next-line: use-life-cycle-interface
    SelfRegistrationSuccessComponent.prototype.ngAfterViewInit = function () {
        this.persistenceService.set('currentStep', JSON.stringify(this.step), { type: StorageType.SESSION });
    };
    /** Redirects to Login Page */
    SelfRegistrationSuccessComponent.prototype.redirectLogin = function () {
        this.router.navigate(['login']);
    };
    return SelfRegistrationSuccessComponent;
}());
export { SelfRegistrationSuccessComponent };
