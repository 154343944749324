import { OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatStepper, MatSnackBar } from '@angular/material';
import { PersistenceService, StorageType } from 'angular-persistence';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
/** Class for  SelfRegistration */
var SelfRegistrationComponent = /** @class */ (function () {
    /**
       * Base constructor
       * @param fb Formbuilder variable
       * @param location location service
       * @param persistenceService PersistenceService
       * @param spinner to get NgxSpinner
       * @param registrationSetupService SelfRegistrationSetupService
       * @param activatedRoute To route to the particular location
       */
    function SelfRegistrationComponent(fb, activatedRoute, router, registrationSetupService, location, persistenceService, spinner, snackBar, manageMoveSharedService, cookieLogoSvc) {
        var _this = this;
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.registrationSetupService = registrationSetupService;
        this.location = location;
        this.persistenceService = persistenceService;
        this.spinner = spinner;
        this.snackBar = snackBar;
        this.manageMoveSharedService = manageMoveSharedService;
        this.cookieLogoSvc = cookieLogoSvc;
        /** subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /** Used to hold loading state */
        this.isLoading = true;
        /** string constants to be displayed in self registration page */
        this.templateString = {
            WELCOME: 'Welcome to Cartus – A leader in global mobility!',
            INFORMATION: "Our primary goal is to provide you with world-class service whether you are one of our corporate client " +
                "contacts, their employee or our supplier partner. You will benefit from our 60+ years of experience as we help " +
                "navigate you through all phases of the relocation process.",
            BANNER_TXT: "Technology That Moves You"
        };
        this.ALREADY_REGISTERED = { statusCode: 400, message: 'You have already registered for $productName, please click below to log into your account' };
        this.LINK_EXPIRED = { statusCode: 410, message: 'This registration link has expired\nPlease contact your Cartus Consultant for a new registration link' };
        this.NOT_FOUND = { statusCode: 404, message: 'We are unable to process your request\nPlease contact your HR sponsor' };
        this.API_ERROR = { statusCode: 500, message: 'We are unable to process your request at this time\nPlease try again later' };
        this.router.events
            .pipe(filter(function (rs) { return rs instanceof NavigationEnd; }))
            .subscribe(function (event) {
            if ((event.url === event.urlAfterRedirects) && (_this.persistenceService.get('completedStep', StorageType.SESSION))) {
                var lastCompletedStep = JSON.parse(_this.persistenceService.get('completedStep', StorageType.SESSION));
                _this.completedStep = lastCompletedStep;
            }
        });
    }
    /** To Initialize Component */
    SelfRegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinner.show();
        this.partyId = this.activatedRoute.snapshot.url[1].path;
        this.getUserDetails(this.partyId);
        var currentStep;
        this.completedStep = this.completedStep
            ? this.completedStep
            : 0;
        this.subscription.add(this.activatedRoute.params.subscribe(function (params) {
            if (!params['id']) {
                _this.changeStep(0);
                _this.persistenceService.set('currentStep', JSON.stringify(0), { type: StorageType.SESSION });
            }
            else {
                currentStep = parseInt(_this.persistenceService.get('currentStep', StorageType.SESSION), 10);
                _this.steppingId = parseInt(params['id'], 10);
                if (currentStep === _this.steppingId) {
                    _this.changeStep(_this.steppingId);
                }
                else {
                    _this.changeStep(currentStep);
                }
            }
        }));
    };
    /**
     * To set the selected index
     * @param event - selected index
     */
    SelfRegistrationComponent.prototype.onStepChange = function (event) {
        var stepVal = "/registration/" + this.partyId + "/step/" + event.selectedIndex;
        this.location.go(stepVal);
    };
    /**
     * to find completed step and change step
     * @param completedStep - step completed
     */
    SelfRegistrationComponent.prototype.onNotify = function (completedStep) {
        this.persistenceService.set('completedStep', JSON.stringify(completedStep), { type: StorageType.SESSION });
        if (completedStep === 0) {
            this.changeStep(1);
        }
        else if (completedStep === 1) {
            this.changeStep(2);
        }
    };
    /**
     * Used to go to given index
     * @param index selected index
     */
    SelfRegistrationComponent.prototype.changeStep = function (index) {
        this.stepper.selectedIndex = index;
        var stepVal = "/registration/" + this.partyId + "/step/" + index;
        this.location.go(stepVal);
    };
    /**
    * To get user details
    * @param partyId - partyId
    */
    SelfRegistrationComponent.prototype.getUserDetails = function (partyId) {
        var _this = this;
        this.subscription.add(this.registrationSetupService
            .getUser(partyId)
            .subscribe(function (userDetails) {
            if (userDetails) {
                _this.userDetails = userDetails;
                var res = _this.cookieLogoSvc.setLogoFromUser(_this.userDetails);
                _this.logoConfig = { logo: res, type: 'main' };
                _this.manageMoveSharedService.updateData(_this.userDetails);
                _this.validateUserRegistration(_this.userDetails.userId);
            }
            else {
                _this.spinner.hide();
                _this.registrationSetupService.currentError = _this.NOT_FOUND;
                _this.router.navigate(['invalidRegistration']);
            }
        }));
    };
    /**
   * To validate registration
   * @param partyId - partyId
   */
    SelfRegistrationComponent.prototype.validateUserRegistration = function (partyId) {
        var _this = this;
        this.subscription.add(this.registrationSetupService
            .validateRegistration(partyId)
            .subscribe(function (response) {
            if (response && response.error && response.error.message) {
                var message = response.error.message.toLowerCase();
                if (response.status === 400 && message.includes('this user is already registered')) {
                    _this.registrationSetupService.currentError = _this.ALREADY_REGISTERED;
                }
                else if (response.status === 400 && message.includes('self registration has expired')) {
                    _this.registrationSetupService.currentError = _this.LINK_EXPIRED;
                }
                else if (response.status === 404) {
                    _this.registrationSetupService.currentError = _this.NOT_FOUND;
                }
                else {
                    _this.registrationSetupService.currentError = _this.API_ERROR;
                }
                _this.spinner.hide();
                _this.router.navigate(['invalidRegistration']);
            }
            else {
                _this.isLoading = false;
                _this.spinner.hide();
            }
        }, function () {
            _this.registrationSetupService.currentError = _this.API_ERROR;
            _this.spinner.hide();
            _this.router.navigate(['invalidRegistration']);
        }));
    };
    return SelfRegistrationComponent;
}());
export { SelfRegistrationComponent };
