import { OnInit, EventEmitter } from '@angular/core';
import { SpringboardApp } from 'src/app/public/models/springboardApps.model';
var SpringboardAppComponent = /** @class */ (function () {
    function SpringboardAppComponent() {
        this.appSelected = new EventEmitter();
    }
    SpringboardAppComponent.prototype.ngOnInit = function () { };
    //** emit the selected app to the parent component to be handled */
    SpringboardAppComponent.prototype.selectApp = function () {
        this.appSelected.emit(this.application.name);
    };
    return SpringboardAppComponent;
}());
export { SpringboardAppComponent };
