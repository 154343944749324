import { OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';
var CustomSnackbarComponent = /** @class */ (function () {
    function CustomSnackbarComponent(_snackRef, data) {
        this._snackRef = _snackRef;
        this.data = data;
    }
    CustomSnackbarComponent.prototype.ngOnInit = function () {
    };
    CustomSnackbarComponent.prototype.dismiss = function () {
        if (!this._snackRef) {
            return;
        }
        this._snackRef.dismiss();
    };
    return CustomSnackbarComponent;
}());
export { CustomSnackbarComponent };
