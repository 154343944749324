import { OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { Router } from '@angular/router';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
var DuplicateRegistrationComponent = /** @class */ (function () {
    function DuplicateRegistrationComponent(appConfig, moveSharedService, router, cookieLogoSvc) {
        this.appConfig = appConfig;
        this.moveSharedService = moveSharedService;
        this.router = router;
        this.cookieLogoSvc = cookieLogoSvc;
        this.templateString = {
            BANNER_TXT: "Technology That Moves You",
            LOGIN_BTN: "MobilifyHR"
        };
        this.contentTextPrefix = 'You have already registered for';
        this.contentTextPostfix = ', please click below to log in to your account:';
    }
    DuplicateRegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.moveSharedService.loginUserDetails.subscribe(function (loginUserDetails) {
            _this.userDetails = loginUserDetails;
            var val = _this.cookieLogoSvc.setLogoFromUser(_this.userDetails);
            _this.logoConfig = { logo: val, type: 'alt' };
            if (_this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('mobilifyHR_ProductName').toString();
            }
            else if (_this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('movePro360_ProductName').toString();
            }
            else if (_this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('benefitsBuilder_ProductName').toString();
            }
            else if (_this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('compensationServices_ProductName').toString();
            }
            _this.templateString.LOGIN_BTN = _this.productName;
        });
    };
    /**
    * To externally navigate to login
    * @param params - params
    */
    DuplicateRegistrationComponent.prototype.navigateToLogin = function (params) {
        var extras;
        if (params && params.code) {
            extras = {};
            this.router.navigate(['login'], extras);
        }
    };
    DuplicateRegistrationComponent.prototype.redirect = function () {
        this.router.navigateByUrl('login');
    };
    return DuplicateRegistrationComponent;
}());
export { DuplicateRegistrationComponent };
