import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
/** Class for  SelfRegistrationCreateUser */
var SelfRegistrationCreateUserComponent = /** @class */ (function () {
    /**
   * Base constructor
   * @param registrationSetupService SelfRegistrationSetupService
   * @param persistenceService PersistenceService
   * @param spinner to get NgxSpinner
   */
    function SelfRegistrationCreateUserComponent(fb, registrationSetupService, persistenceService, ngxSpinner, snackBar, logSvc) {
        this.fb = fb;
        this.registrationSetupService = registrationSetupService;
        this.persistenceService = persistenceService;
        this.ngxSpinner = ngxSpinner;
        this.snackBar = snackBar;
        this.logSvc = logSvc;
        /** variable for isCandidateOrTransferee */
        this.isCandidateorTransferee = true;
        /** variable to store user details */
        this.addUser = {};
        /** Output parameter step number notified to parent component by emiting */
        this.notify = new EventEmitter();
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /** Stores the strings used in the template */
        this.templateString = {
            FORM_TITLE: "Creating an account is quick and easy!",
            PASSWORD_INSTRUCTION_TITLE: "Passwords must be at least 10 characters, and must contain the following details:",
            PRIVACY_NOTICE_TXT: "By clicking the button below you are agreeing to our",
            PRIVACY_NOTICE: "Privacy Notice",
            EMAIL: 'Email',
            PASS: 'Password',
            CONFIRM_PASS: 'Confirm Password',
            CREATE_ACC_BTN: 'Create Account',
            expiredRegistration: 'This registration link has expired.  Please contact your Cartus Consultant for a new registration link.',
            duplicateEmail: 'This email cannot be used to create an account.  Please use a different email or contact Cartus.',
            apiError: 'We are unable to process your request at this time. Please try again later.'
        };
        /** Password instruction display string */
        this.passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
    }
    // Detect window size
    SelfRegistrationCreateUserComponent.prototype.onresize = function () {
        this.getWindowSize();
    };
    /** To Initialize Component */
    SelfRegistrationCreateUserComponent.prototype.ngOnInit = function () {
        this.getWindowSize();
        this.createControl();
    };
    /** To Create form Controls */
    SelfRegistrationCreateUserComponent.prototype.createControl = function () {
        this.selfRegistrationSetupForm = this.fb.group({
            email: new FormControl(this.userDetails.emailAddress, Validators.compose([
                Validators.required,
                Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9-]+[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,3}$')
            ])),
            password: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$'),
            ])),
            confirmpassword: ['', Validators.required]
        });
        if (this.userDetails.roleName !== 'candidate' && this.userDetails.roleName !== 'transferee') {
            this.selfRegistrationSetupForm.controls['email'].disable();
            this.isCandidateorTransferee = false;
        }
        this.selfRegistrationSetupForm.controls['email'].markAsTouched();
    };
    /** Store the current step in session */
    // tslint:disable-next-line: use-life-cycle-interface
    SelfRegistrationCreateUserComponent.prototype.ngAfterViewInit = function () {
        this.persistenceService.set('currentStep', JSON.stringify(this.step), { type: StorageType.SESSION });
    };
    /** To check whether both passwords match */
    SelfRegistrationCreateUserComponent.prototype.checkPassword = function () {
        this.selfRegistrationSetupForm.get('password').value === this.selfRegistrationSetupForm.get('confirmpassword').value ?
            this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors(null) :
            this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors({
                notSame: true
            });
    };
    /** checks if password contains parts of username */
    SelfRegistrationCreateUserComponent.prototype.checkForUsername = function () {
        var username = this.selfRegistrationSetupForm.get('email').value.split(/[^\w\s]|_/g);
        var password = this.selfRegistrationSetupForm.get('password').value.toLowerCase();
        for (var _i = 0, username_1 = username; _i < username_1.length; _i++) {
            var name_1 = username_1[_i];
            if (name_1.length >= 4 && password.includes(name_1.toLowerCase())) {
                this.selfRegistrationSetupForm.controls['password'].setErrors({
                    containsUsername: true
                });
                break;
            }
            else if (!this.selfRegistrationSetupForm.get('password').hasError('pattern') &&
                !this.selfRegistrationSetupForm.get('password').hasError('required')) {
                this.selfRegistrationSetupForm.controls['password'].setErrors(null);
            }
        }
    };
    /** To create user */
    SelfRegistrationCreateUserComponent.prototype.createUser = function () {
        var _this = this;
        this.ngxSpinner.show();
        this.addUser.username = this.selfRegistrationSetupForm.controls['email'].value;
        this.addUser.password = this.selfRegistrationSetupForm.controls['password'].value;
        this.addUser.partyId = this.userDetails.userId;
        this.addUser.orderRequestId = this.userDetails.orderRequestId;
        this.addUser.product = this.userDetails.product;
        this.addUser.roleName = this.userDetails.roleName;
        this.addUser.systemOfOrigin = this.userDetails.systemOfOrigin;
        this.subscription.add(this.registrationSetupService
            .registerUser(this.addUser)
            .subscribe(function (response) {
            if (!!response) {
                var logError = void 0;
                var message = void 0;
                var snackbarMessage = void 0;
                if (response.status && response.status === 400) { // 400 error
                    if (response.error && response.error.message) {
                        message = response.error.message.toLowerCase();
                        if (message.includes('self registration has expired')) {
                            snackbarMessage = _this.templateString.expiredRegistration;
                        }
                        else if (message.includes('an object with this field already exists in the current organization')) {
                            snackbarMessage = _this.templateString.duplicateEmail;
                        }
                        else {
                            logError = true;
                            snackbarMessage = _this.templateString.apiError;
                        }
                    }
                }
                if (response.status && response.status !== 400 && response.error) { // other error
                    logError = true;
                    snackbarMessage = _this.templateString.apiError;
                }
                if (!!snackbarMessage) {
                    _this.ngxSpinner.hide();
                    _this.snackBar.open(snackbarMessage, undefined, { duration: 5000 });
                    if (!!logError) {
                        _this.logSvc.logError(response);
                    }
                }
                else {
                    _this.ngxSpinner.hide();
                    var currentStep = void 0;
                    currentStep = parseInt(_this.persistenceService.get('currentStep', StorageType.SESSION), 10);
                    _this.notify.emit(currentStep);
                }
            }
        }));
    };
    /**
     * To set error message to fields
     * @param fieldName - Field Name
     */
    SelfRegistrationCreateUserComponent.prototype.getErrorMessage = function (fieldName) {
        if (fieldName === 'EMAIL') {
            return this.selfRegistrationSetupForm.get('email').hasError('required')
                ? 'You must enter a User ID'
                : this.selfRegistrationSetupForm.get('email').hasError('pattern')
                    ? 'User ID must be a valid email address'
                    : '';
        }
        if (fieldName === 'PASSWORD') {
            return this.selfRegistrationSetupForm.get('password').hasError('required')
                ? 'You must enter a password'
                : this.selfRegistrationSetupForm.get('password').hasError('pattern')
                    ? 'You must match password complexity rules'
                    : this.selfRegistrationSetupForm.get('password').hasError('containsUsername')
                        ? 'Password cannot contain part of email'
                        : '';
        }
        if (fieldName === 'CONFIRMPASSWORD') {
            return this.selfRegistrationSetupForm.get('confirmpassword').hasError('required')
                ? 'You must enter a password'
                : '';
        }
    };
    /** To get window size and responsiveview */
    SelfRegistrationCreateUserComponent.prototype.getWindowSize = function () {
        var windowWidth = window.innerWidth;
        if (windowWidth <= 959) {
            this.responsiveView = true;
        }
        else {
            this.responsiveView = false;
        }
    };
    return SelfRegistrationCreateUserComponent;
}());
export { SelfRegistrationCreateUserComponent };
