import { OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { Router } from '@angular/router';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
var InvalidRegistrationComponent = /** @class */ (function () {
    function InvalidRegistrationComponent(appConfig, moveSharedService, router, cookieLogoSvc, registrationSetupService) {
        this.appConfig = appConfig;
        this.moveSharedService = moveSharedService;
        this.router = router;
        this.cookieLogoSvc = cookieLogoSvc;
        this.registrationSetupService = registrationSetupService;
        this.templateString = {
            BANNER_TXT: "Technology That Moves You",
            LOGIN_BTN: "MobilifyHR"
        };
        /**Used to hold hidden status for redirect button */
        this.showLoginButton = false;
    }
    InvalidRegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentError = this.registrationSetupService.currentError;
        this.moveSharedService.loginUserDetails.subscribe(function (loginUserDetails) {
            _this.userDetails = loginUserDetails;
            var val = _this.cookieLogoSvc.setLogoFromUser(_this.userDetails);
            _this.logoConfig = { logo: val, type: 'alt' };
            if (_this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('mobilifyHR_ProductName').toString();
            }
            else if (_this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('movePro360_ProductName').toString();
            }
            else if (_this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('benefitsBuilder_ProductName').toString();
            }
            else if (_this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
                _this.productName = _this.appConfig.getConfig('compensationServices_ProductName').toString();
            }
            else {
                _this.productName = 'this service';
            }
            _this.templateString.LOGIN_BTN = _this.productName;
        });
        if (!!this.currentError) {
            if (this.currentError.statusCode === 400)
                this.showLoginButton = true;
            var messageLines = this.currentError.message.replace('$productName', this.productName).split('\n');
            this.contentText1 = messageLines[0];
            this.contentText2 = messageLines.length > 1 ? messageLines[1] : '';
        }
    };
    InvalidRegistrationComponent.prototype.redirect = function () {
        this.router.navigateByUrl('login');
    };
    return InvalidRegistrationComponent;
}());
export { InvalidRegistrationComponent };
