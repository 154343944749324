import { OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';
var SessionTimeoutComponent = /** @class */ (function () {
    /**
     * Base constructor
     * @param _snackRef MatSnackBarRef
     */
    function SessionTimeoutComponent(_snackRef, data) {
        this._snackRef = _snackRef;
        this.data = data;
    }
    SessionTimeoutComponent.prototype.ngOnInit = function () { };
    /** To Dismiss snackbar */
    SessionTimeoutComponent.prototype.dismiss = function () {
        this._snackRef.dismissWithAction();
    };
    return SessionTimeoutComponent;
}());
export { SessionTimeoutComponent };
